"use client";

import { useEffect, useState } from "react";
import { Grid, Link as MuiLink, Skeleton, Stack, Typography } from "@mui/material";
import isNil from "lodash/isNil";
import { startOfMonth } from "date-fns/startOfMonth";
import { sub } from "date-fns/sub";

import { TopicDashboardSubSectionData } from "../../../dashboard/types";
import { MhcGeographyEnum, MhcSeason, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";
import { BaseProps } from "modules/Topics/types";

import { DEFAULT_COLUMN_SPACING } from "layout/configuration";
import { STATE_LOCATION_NAME } from "common/constants";
import { dataStories, DataStory } from "common/content/dataStories";
import { dateToIsoString } from "common/util/date";
import { useIsMobile, useIsTablet } from "common/util/hooks/useIsMobile";
import {
  getFullSeasonName,
  getSeasonSlug,
  isCurrentSeason,
  seasonIsAllTime
} from "common/util/mhcSeason";
import { fetchMapDataV2 } from "modules/Topics/util/fetchingFunctions/fetchMapDataV2";

import { ContentCardSectionTitle } from "layout/card/ContentCardSectionTitle";
import { ContentCardSubSection } from "layout/card/ContentCardSubSection";
import { ContentCardTextBlock } from "layout/card/ContentCardTextBlock";
import { CovidPageTopicContentCardWrapper } from "../../common/components/CovidPageTopicContentCardWrapper";
import { InvestigateMapFlexWrapper } from "common/components/InvestigateMap/V2/Wrapper/InvestigateMapFlexWrapperV3";
import DataStoryCard from "common/components/Stories/DataStoryCard";
import { CovidPageProps } from "modules/Topics/pages/items/CovidConfig";
import { getTopicDashboardSection } from "../../../../getTopicDashboardSection";
import { getInvestigateMapConfig } from "../config";
import { OverviewAdditionalData } from "../getData";
import Kpis from "./Kpis";

type PageProps = BaseProps & CovidPageProps<OverviewAdditionalData>;

const Content: React.FC<PageProps> = ({ location, notes = [], sections, season, seasons }) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [mapProps, setMapProps] = useState<TopicDashboardSubSectionData["mapV2"] | null>(null);
  const { subSections, title, id, dates } = getTopicDashboardSection(sections ?? [], "overview");

  useEffect(() => {
    const config = getInvestigateMapConfig({
      season,
      locationId: location.id,
      isCurrentSeason: season?.isCurrent ?? false,
      isAllTime: season?.isAllTime ?? false,
      lastUpdatedOn: dates.dataDate
        ? dateToIsoString(sub(startOfMonth(dates.dataDate), { months: 0 }))
        : undefined
    });
    if (isNil(config)) return;
    void fetchMapDataV2(config).then((mapProps) =>
      setMapProps(mapProps as TopicDashboardSubSectionData["mapV2"])
    );
    // Adding `dates.dataDate` causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.id, season]);

  let storyProps: DataStory =
    (dataStories.find(({ slug }) => slug === "vaccinations") as DataStory) ?? {};
  storyProps = {
    ...storyProps,
    datePublished: undefined,
    title: "COVID-19 Vaccination Success Story",
    description: "Explore COVID-19 Vaccinations in the Hispanic Community",
    context: "Data Stories"
  };

  const seasonsIsCurrent = isCurrentSeason(season, seasons ?? []);
  const _seasonsIsAllTime = seasonIsAllTime(season);

  if (!seasonsIsCurrent && mapProps) {
    // Update the stat identifier name for investigate option based on season
    mapProps.stats = mapProps.stats.map((s) => {
      const newName = s.name.replace(
        /\(This Season\)|This Season/gi,
        _seasonsIsAllTime ? "(All Time)" : getSeasonSlug(season as MhcSeason)
      );
      return {
        ...s,
        name: newName,
        fullName: newName
      };
    });
  }

  const kpis = subSections?.find(({ id }) => id === "keyData")?.kpiGroup?.kpis ?? [];

  let mapHeight: string | undefined = undefined;
  isMobile && (mapHeight = "50svh");
  isTablet && (mapHeight = "50svh");
  const isStateOrCounty = location.id === "state" || location.id.includes("county");

  return (
    <CovidPageTopicContentCardWrapper
      title={title}
      id={id}
      locationId={location.id}
      season={season}
      seasons={seasons}
      dates={{ ...dates, granularity: MhcTimeSeriesGranularityEnum.Day }}
      notes={notes}
    >
      <ContentCardSubSection title="Introduction">
        <Grid container spacing={{ xs: 4, md: DEFAULT_COLUMN_SPACING }}>
          <Grid item xs={12} lg={4.5}>
            <ContentCardTextBlock
              component="p"
              title="What is COVID-19?"
              titleProps={{ fontWeight: 600, variant: "h6" }}
            >
              <Typography>
                COVID-19, short for Coronavirus Disease - 2019, is a sickness caused by a virus
                called coronavirus. It spreads from person to person when someone with the virus
                coughs, sneezes, or talks, and their tiny droplets get into the air and are breathed
                in by others.
              </Typography>
              <Typography>
                The best way to protect yourself is to get an updated COVID-19 vaccine. Everyone 6
                months and older is eligible for vaccination. Learn more by visiting{" "}
                <MuiLink href="//de.gov/coronavirus" target="_blank">
                  de.gov/coronavirus
                </MuiLink>
                .
              </Typography>
            </ContentCardTextBlock>
          </Grid>
          <Grid item xs={12} lg={7.5} spacing={0}>
            {storyProps && (
              <DataStoryCard
                imageObjectPosition="top"
                {...storyProps}
                linkAsButton={false}
                showDescription
              />
            )}
          </Grid>
        </Grid>
      </ContentCardSubSection>
      {isStateOrCounty && !isNil(mapProps) && (
        <ContentCardSubSection>
          <InvestigateMapFlexWrapper
            {...mapProps}
            mapHeight={mapHeight}
            omitGeographies={[MhcGeographyEnum.Municipality]}
            stackInvestigations={false}
            noMaxHeight
            contentAboveMap={
              <>
                <ContentCardSectionTitle title="Investigate Map" />
                <Typography mb={2}>
                  Map of {STATE_LOCATION_NAME} showing COVID-19 cases (as rates per 100,000 people)
                  and vaccinations (shown as percentages).
                </Typography>
                {isNil(mapProps) && (
                  <Stack width="100%" direction="column" flex={1}>
                    <Skeleton
                      variant="rectangular"
                      height={mapHeight}
                      width="100%"
                      sx={{ display: "flex", flexGrow: 1, minWidth: "min-content" }}
                    />
                  </Stack>
                )}
              </>
            }
          >
            <Kpis
              kpis={kpis}
              isAllTime={season?.isAllTime ?? false}
              seasonStartDate={season?.startDate}
              isCurrentSeason={isCurrentSeason(season, seasons ?? [])}
              seasonName={getFullSeasonName(season)}
              seasonEndDate={season?.endDate}
              doseAuthorizationDate={season?.startDateForVaccine}
            />
          </InvestigateMapFlexWrapper>
        </ContentCardSubSection>
      )}
    </CovidPageTopicContentCardWrapper>
  );
};

export default Content;
