"use client";

import React, { ReactNode } from "react";
import { Box, Stack, StackProps } from "@mui/material";

import { InvestigateMapPropsV2 } from "common/components/InvestigateMap/V2/util/types";
import { MhcMapFeatureCollection } from "graphqlApi/types";

import { SITE_HEADER_HEIGHT } from "layout/configuration";

import InvestigateMapV3 from "common/components/InvestigateMap/V2/InvestigateMapV3";

export type InvestigateMapFlexWrapperProps = InvestigateMapPropsV2<MhcMapFeatureCollection> & {
  /**  Element/Component/Node that renders on top of the map and all other components */
  contentBeforeMap?: React.ReactNode;
  /**  Element/Component/Node that renders on top of map respecting the flex stack */
  contentAboveMap?: React.ReactNode;
  /** Layout direction (e.g. column, row)  */
  direction?: StackProps["direction"];
  /**  Force the children to render at 100% width of parent container */
  fullWidthChildren?: boolean;
  /** Gap space between elements */
  gap?: number;
  children?: ReactNode;
  noMaxHeight?: boolean;
};

/**
 * Extension of InvestigateMapFlexWrapper that renders InvestigateMapV3.
 *
 * @param params
 * @param params.children
 * @param params.contentAboveMap
 * @param params.contentBeforeMap
 * @param params.gap
 * @param params.direction
 * @param params.noMaxHeight
 * @param params.fullWidthChildren
 *
 * @returns InvestigateMapV3 within layout supporting other components/elements
 *
 * @see InvestigateMapFlexWrapper
 * @see InvestigateMapV3
 *
 */
export const InvestigateMapFlexWrapper: React.FC<InvestigateMapFlexWrapperProps> = ({
  children,
  contentAboveMap,
  contentBeforeMap,
  gap = 5,
  direction,
  noMaxHeight = false,
  fullWidthChildren = false,
  ...props
}) => (
  <Stack>
    {contentBeforeMap}
    <Box
      display="flex"
      width="100%"
      minHeight={{ xs: undefined, md: 600 }}
      maxHeight={noMaxHeight ? undefined : { xs: undefined, md: 1000 }}
      height={
        noMaxHeight
          ? undefined
          : {
              xs: undefined,
              md: `calc(100vh - ${SITE_HEADER_HEIGHT * 3}px)`
            }
      }
    >
      <Stack
        gap={gap}
        flexDirection={{ xs: "column", md: direction ? "column" : "row" }}
        flex={1}
        overflow="hidden"
        flexWrap={fullWidthChildren ? "wrap" : "nowrap"}
      >
        <Stack
          flex={{ xs: undefined, md: `1 1 ${fullWidthChildren ? "100%" : "50%"}` }}
          flexGrow={{ xs: undefined, md: 1 }}
        >
          {contentAboveMap}
          <InvestigateMapV3 {...props} />
        </Stack>
        <Stack
          gap={4}
          flex={{ xs: undefined, md: `1 1 ${fullWidthChildren ? "100%" : "50%"}` }}
          overflow="hidden"
        >
          {children}
        </Stack>
      </Stack>
    </Box>
  </Stack>
);
