import { BivariateQuadrant } from "./types";

export const bivariateDefaultFeatureColorFunctionBuilder = (
  xCategories: BivariateQuadrant[],
  yCategories: BivariateQuadrant[],
  colors: string[][]
) => {
  const bivariateDefaultFeatureColorFunction: (
    xValue: number,
    yValue: number
  ) => string | undefined = (xValue, yValue) => {
    const xIndex = xCategories.findIndex((quadrant) => {
      if (xValue >= quadrant.min) {
        if (quadrant.max !== undefined) {
          return xValue < quadrant.max;
        } else {
          return true;
        }
      }
      return false;
    });
    const yIndex = yCategories.findIndex((quadrant) => {
      if (yValue >= quadrant.min) {
        if (quadrant.max !== undefined) {
          return yValue < quadrant.max;
        } else {
          return true;
        }
      }
      return false;
    });
    if (xIndex !== undefined && yIndex !== undefined && xIndex >= 0 && yIndex >= 0) {
      const row = colors[yIndex];
      return row ? row[xIndex] ?? undefined : undefined;
    }
    return undefined;
  };
  return bivariateDefaultFeatureColorFunction;
};
