import { TopicDashboardSectionConfig, TopicDashboardSubsectionConfig } from "../../dashboard/types";
import { PageConfigurator } from "../types";
import { InvestigateMapPropsV2 } from "common/components/InvestigateMap/V2/util/types";
import { MhcGeographyEnum, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { getDatesForDemographicStatQueries } from "../util/demographics";
import { DEFAULT_BIVARIATE_COLOR_MAP } from "common/components/BivariateSection/util/colors";
import { lastMonthDates } from "common/util/date";
import { formatDateByGranularity } from "common/util/formatHelpers";
import { getUtcDateFromString } from "common/util/utcDateFromString";
import { demographicInvestigateChartConfig } from "modules/Topics/util/config";

import { SeasonForPage } from "modules/Topics/pages/items/CovidConfig";
import { FetchMapDataV2Params } from "../../../fetchingFunctions/fetchMapDataV2";

// TODO: Add this to the API in MhcVaccine?
export const DOSE_AUTHORIZATION_DATE = "2023-09-11";

export const statIds = {
  vaccinatedPercent: "COVID19_CUMULATIVE_UP_TO_DATE_FALL_2023_VACCINATIONS_PERCENT",
  vaccinatedCount: "COVID19_CUMULATIVE_UP_TO_DATE_FALL_2023_VACCINATIONS_COUNT",
  admins: "COVID19_VACCINE_ADMINISTRATIONS_COUNT",
  vaccinatedPercentV2: "COVID19_CUMULATIVE_VACCINATIONS_SEASON_CRUDE_RATE",
  vaccinatedCountV2: "COVID19_CUMULATIVE_VACCINATIONS_SEASON_COUNT"
};

const defaultStatIdConfig = {
  needSeries: false,
  granularity: MhcTimeSeriesGranularityEnum.Month
};

const quadrants = {
  // Percent vaccinated
  x: [{ min: 0, max: 12 }, { min: 12, max: 24 }, { min: 24, max: 32 }, { min: 32 }],
  // SVI
  y: [{ min: 0, max: 0.25 }, { min: 0.25, max: 0.5 }, { min: 0.5, max: 0.75 }, { min: 0.75 }]
};

export const vaccinationCoverageConfig = (useNewVaxStat: boolean) => {
  return {
    id: "bivariateAnalysis",
    bivariateAnalysisConfig: {
      title: "",
      statLoadingVersion: 2,
      bivariateValueComparisonName: "",
      bivariateValueComparisonOtherValueName: "",
      geography: MhcGeographyEnum.CensusTract,
      supportedGeographies: [MhcGeographyEnum.CensusTract],
      xStatIdConfig: {
        identifier: useNewVaxStat === true ? statIds.vaccinatedPercentV2 : statIds.vaccinatedPercent
      },
      yStatIdConfig: {
        identifier: "SVI_OVERALL_RATING_CRUDE_RATE"
      },
      topRightTitle: "Less Vulnerable, More Vaccinations",
      bottomLeftTitle: "More Vulnerable, Fewer Vaccinations",
      quadrants,
      useYAxisForSelectedValue: true,
      titles: {
        xAxisLabels: ["Fewer Vaccinations", "", "", "More Vaccinations"],
        yAxisMinTitle: "Less Vulnerable",
        yAxisMaxTitle: "More Vulnerable"
      },
      sliderProps: (geography) =>
        geography === MhcGeographyEnum.CensusTract
          ? {
              leftSubtitle: "Less Vulnerable",
              rightSubtitle: "More Vulnerable",
              centerSubtitle: "",
              sliderValueTitle: "",
              leftLabel: "0",
              leftValue: 0,
              rightLabel: "1",
              rightValue: 1,
              barHeight: 30,
              width: "100%",
              fillDirection: "right_to_left",
              sectionColors: Object.values(DEFAULT_BIVARIATE_COLOR_MAP).reverse()
            }
          : null
    }
  } as TopicDashboardSubsectionConfig;
};

type GetInvestigateMapConfig = {
  season: SeasonForPage | null | undefined;
  locationId: string;
  lastUpdatedOn?: string;
};
export const getInvestigateMapConfig = ({
  season,
  locationId,
  lastUpdatedOn
}: GetInvestigateMapConfig): FetchMapDataV2Params => {
  let mapConfig = null;
  const seasonIsCurrent = season?.isCurrent ?? false;
  const seasonIsAllTime = season?.isAllTime ?? false;
  const isStateOrCounty = locationId === "state" || locationId.includes("county");
  if (isStateOrCounty) {
    const mapIdentifier = !season?.name?.includes("2023-2024")
      ? statIds.vaccinatedPercentV2
      : statIds.vaccinatedPercent;
    const mapGranularity = MhcTimeSeriesGranularityEnum.Month;
    const mapDates =
      seasonIsCurrent || seasonIsAllTime
        ? lastMonthDates(lastUpdatedOn, seasonIsCurrent ? 0 : undefined)
        : lastMonthDates(season?.endDate as string);
    const overrideDateByStatMap: InvestigateMapPropsV2["overrideDateByStatMap"] = {
      [mapIdentifier]: {
        startsOn: formatDateByGranularity({
          value: getUtcDateFromString(DOSE_AUTHORIZATION_DATE),
          granularity: mapGranularity
        })
      }
    };
    mapConfig = {
      availableGeographies: [MhcGeographyEnum.ZipCode, MhcGeographyEnum.County],
      omitGeographies: [MhcGeographyEnum.Municipality],
      defaultGeography: MhcGeographyEnum.ZipCode,
      stats: [
        {
          ...mapDates,
          ...defaultStatIdConfig,
          identifier: mapIdentifier,
          granularity: mapGranularity
        }
      ],
      overrideDateByStatMap,
      kpiGroupConfig: {
        statIdConfigs: [
          {
            identifier: mapIdentifier,
            ...defaultStatIdConfig
          }
        ]
      }
    };
  }
  return mapConfig as FetchMapDataV2Params;
};

const subSections: PageConfigurator<TopicDashboardSubsectionConfig> = (options) => {
  const { season } = options ?? {};
  const { countDates, percentageDates } = getDatesForDemographicStatQueries(season);
  const useNewVaxStat = !season?.name?.includes("2023-2024");
  const stats =
    useNewVaxStat === true
      ? [statIds.vaccinatedPercentV2, statIds.vaccinatedCountV2]
      : [statIds.vaccinatedPercent, statIds.vaccinatedCount];

  return [
    {
      id: "keyData",
      kpiGroupConfig: {
        ignoreLoadedStats: true,
        showAboutTheData: true,
        statIdConfigs: stats.map((identifier) => ({
          identifier,
          ...defaultStatIdConfig,
          granularity: "week",
          startsOn: season?.startDate,
          endsOn: season?.endDate
        }))
      }
    },
    demographicInvestigateChartConfig({
      countStatId: {
        identifier: useNewVaxStat === true ? statIds.vaccinatedCountV2 : statIds.vaccinatedCount,
        ...countDates
      },
      comparisonPercentStatId: { identifier: "POPULATION_TOTAL_PERCENT", ...percentageDates }
    })
  ];
};

export const sections: PageConfigurator<TopicDashboardSectionConfig> = (options) => [
  {
    id: "vaccines",
    title: "COVID-19 Vaccinations",
    subSections: subSections(options),
    granularity: MhcTimeSeriesGranularityEnum.Week
  }
];
