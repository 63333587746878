import pick from "lodash/pick";

import {
  StatIdConfig,
  TopicDashboardSectionConfig,
  TopicDashboardSubsectionConfig
} from "../../dashboard/types";
import { PageConfigurator } from "../types";
import { InvestigateMapPropsV2 } from "common/components/InvestigateMap/V2/util/types";
import { MhcGeographyEnum, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { chartConfigsForLocationComparison } from "../util";
import { lastMonthDates } from "common/util/date";
import { formatDateByGranularity } from "common/util/formatHelpers";
import { getUtcDateFromString } from "common/util/utcDateFromString";

import { SeasonForPage } from "modules/Topics/pages/items/CovidConfig";
import { statIds as caseStatIds } from "../Cases/config";
import { statIds as deathsStatIds } from "../Deaths/config";
import { statIds as hospStatIds } from "../Hospitalizations/config";
import { statIds as vaccinatedStatIds } from "../Vaccines/config";

const keys = [
  "casesNewRate",
  "casesNewCount",
  "casesTotal",
  "casesSeasonCount",
  "casesSeasonRate",
  "vaccinated",
  "hospitalAdmins",
  "deathsSeason",
  "deathsTotalCount"
] as const;

type keyStrings = (typeof keys)[number];

export const statIds = (useNewVaxStat: boolean): Record<keyStrings, string> => ({
  casesNewRate: caseStatIds.casesNewRate,
  casesNewCount: caseStatIds.casesCount,
  casesTotal: caseStatIds.casesTotalRate,
  casesSeasonCount: caseStatIds.cumulativeSeason,
  casesSeasonRate: caseStatIds.cumulativeSeasonRate,
  vaccinated:
    useNewVaxStat == true
      ? vaccinatedStatIds.vaccinatedPercentV2
      : vaccinatedStatIds.vaccinatedPercent,
  hospitalAdmins: hospStatIds.hospitalizationAdmissionsCount,
  deathsSeason: deathsStatIds.deathsSeasonCount,
  deathsTotalCount: deathsStatIds.deathsTotalCount
});
export const monthlyStats = [statIds(false).deathsSeason, statIds(false).deathsTotalCount];

export const supportedGeographies: MhcGeographyEnum[] = [
  MhcGeographyEnum.County,
  MhcGeographyEnum.ZipCode,
  MhcGeographyEnum.CensusTract
];

const defaultStatIdConfig = {
  needSeries: false
};

type GetInvestigateMapConfig = {
  locationId: string;
  season: SeasonForPage | null | undefined;
  isCurrentSeason?: boolean;
  isAllTime?: boolean;
  lastUpdatedOn?: string;
};
export const getInvestigateMapConfig = ({
  locationId,
  season,
  lastUpdatedOn
}: GetInvestigateMapConfig) => {
  const seasonIsCurrent = season?.isCurrent ?? false;
  const seasonIsAllTime = season?.isAllTime ?? false;
  const casesRateKey = seasonIsAllTime ? "casesTotal" : "casesSeasonRate";
  const stats = statIds(!season?.name?.includes("2023-2024"));
  const mappableStatIds = Object.values(pick(stats, [casesRateKey, "vaccinated"]));
  const overrideDateByStatMap: InvestigateMapPropsV2["overrideDateByStatMap"] = {};
  const mapGranularity = MhcTimeSeriesGranularityEnum.Month;
  mappableStatIds.reduce((dateMap, id) => {
    let startsOn;
    switch (id) {
      case stats.casesSeasonRate:
        startsOn = formatDateByGranularity({
          value: getUtcDateFromString(season?.startDate as string),
          granularity: mapGranularity
        });
        break;
      case stats.casesTotal:
        startsOn = formatDateByGranularity({
          value: getUtcDateFromString(season?.startDate as string),
          granularity: mapGranularity
        });
        break;
      case stats.vaccinated:
        startsOn = formatDateByGranularity({
          value: getUtcDateFromString(season?.startDateForVaccine),
          granularity: mapGranularity
        });
        break;
      default:
        startsOn = null;
    }
    dateMap ||= {};
    startsOn && (dateMap[id as string] = { startsOn });
    return dateMap;
  }, overrideDateByStatMap);
  const mapDates =
    seasonIsCurrent || seasonIsAllTime
      ? lastMonthDates(lastUpdatedOn)
      : lastMonthDates(season?.endDate as string);

  return {
    locationId,
    availableGeographies: supportedGeographies,
    defaultGeography: MhcGeographyEnum.ZipCode,
    omitGeographies: [MhcGeographyEnum.Municipality],
    stats: mappableStatIds.map((identifier) => ({
      ...defaultStatIdConfig,
      granularity: mapGranularity,
      identifier,
      ...mapDates
    })),
    overrideDateByStatMap
  };
};

const subSections: PageConfigurator<TopicDashboardSubsectionConfig> = (options) => {
  const { isAllTime, season } = options ?? {};
  const useNewVaxStat = !season?.name?.includes("2023-2024");

  const stats = statIds(useNewVaxStat ?? false);
  const checkName = (season: SeasonForPage | null | undefined, check: string) => {
    return season?.name?.includes(check) || season?.fullName?.includes(check);
  };
  const shouldShowVaxKpi = !(
    checkName(season, "2020-2020") ||
    checkName(season, "2020-2021") ||
    checkName(season, "2021-2022")
  );
  const kpiStats: StatIdConfig[] = [
    isAllTime
      ? { identifier: stats.casesTotal, ...defaultStatIdConfig }
      : { identifier: stats.casesNewCount, ...defaultStatIdConfig },
    ...((shouldShowVaxKpi
      ? [
          {
            identifier: stats.vaccinated,
            ...defaultStatIdConfig,
            granularity: "week",
            startsOn: season?.startDate,
            endsOn: season?.endDate
          }
        ]
      : []) as StatIdConfig[]),
    {
      identifier: stats.hospitalAdmins,
      needStat: true
    },
    isAllTime
      ? { identifier: stats.deathsTotalCount, ...defaultStatIdConfig }
      : { identifier: stats.deathsSeason, ...defaultStatIdConfig }
  ];

  return [
    {
      id: "keyData",
      kpiGroupConfig: {
        statIdConfigs: kpiStats,
        statIdsWithTrend: [stats.hospitalAdmins]
      }
    }
  ];
};

export const sections: PageConfigurator<TopicDashboardSectionConfig> = (options) => [
  {
    id: "overview",
    title: "COVID-19 Overview",
    subSections: subSections(options),
    granularity: MhcTimeSeriesGranularityEnum.Week
  },
  {
    id: "newCases",
    title: "New Cases by County",
    subSections: [
      {
        id: "newCases",
        title: "New Cases (Current Season)",
        chartConfigs: chartConfigsForLocationComparison({
          id: "new-cases",
          identifier: statIds(false).casesNewRate,
          title: ""
        })
      }
    ]
  }
];
